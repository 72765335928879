<template>
  <div class="main">
    <div class="login-area py-120">
      <div class="container">
        <div class="col-md-5 mx-auto">
          <div class="login-form">
            <div class="login-header">
              <a href="/"> <img :src="imgUrl" alt=""></a>
              <h4>注册用户</h4>
            </div>
            <el-form :model="user" :rules="rules" ref="ruleForm">
              <div class="form-group">
                <label>用户昵称</label>
                <el-form-item prop="netname">
                  <el-input class="el-input" type="text"
                            v-model="user.netname"></el-input>
                </el-form-item>
                <p>支持汉字、字母、数字，请勿使用特殊字符</p>
              </div>
              <div class="form-group">
                <label>手机号</label>
                <el-form-item prop="phone">
                  <el-input class="el-input" type="text" autocomplete="new-password" v-model="user.phone"></el-input>
                </el-form-item>
                <p>手机号作为您找回密码的重要凭证</p>
                <div id="sendCode" class="btn"
                     :class="sendCodeBtn.isGrey ? 'btn-outline-no':'btn-outline-code'"
                     @click="sendPhoneCode">
                  获取验证码 <span v-if="sendCodeBtn.isGrey">({{ sendCodeBtn.count }})</span>
                </div>
              </div>

              <div class="form-group">
                <label>短信验证码</label>
                <el-form-item prop="phoneCode">
                  <el-input class="el-input" type="text" autocomplete="new-password"
                            v-model="user.phoneCode"></el-input>
                </el-form-item>
                <p>请填写收到的短信验证码</p>
              </div>

              <div class="form-group">
                <label>密码</label>
                <el-form-item prop="password">
                  <el-input class="el-input" type="password" autocomplete="new-password"
                            v-model="user.password"></el-input>
                </el-form-item>
                <p>字母、数字或者英文符号，最短8位，区分大小写</p>
              </div>
              <div class="form-group">
                <label>确认密码</label>
                <el-form-item prop="passwordConfirm">
                  <el-input class="el-input" type="password" autocomplete="new-password"
                            v-model="user.passwordConfirm"></el-input>
                </el-form-item>
                <p>请再次输入密码</p>
              </div>
              <div class="form-check form-group">
                <input class="form-check-input" type="checkbox" checked="checked" disabled="disabled" id="agree">
                <label class="form-check-label" for="agree">
                  我同意并遵守 <a>《ONE ORG 平台服务协议》</a>
                </label>
              </div>
              <div class="d-flex align-items-center">
                <div ref="registerBtn" class="login-btn" @click="register"><i class="far fa-paper-plane"></i> 注册</div>
              </div>
            </el-form>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import "@assets/css/register.css"

export default {
  name: "register",
  data() {

    const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error('请输入正确的11位手机号码'))
        } else {
          callback()
        }
      }, 100)
    }

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.user.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }

    return {
      imgUrl: require('@assets/images/logo.svg'),
      user: {
        phone: '',
        phoneCode: '',
        netname: '',
        password: '',
        passwordConfirm: ''
      },
      rules: {
        netname: [
          {required: true, message: '请输入用户昵称', trigger: 'blur'}
        ],
        phone: [
          {required: true, validator: validatePhone, trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, message: '密码长度不符合最短8位', trigger: 'blur'}
        ],
        passwordConfirm: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      },
      sendCodeBtn: {
        isGrey: false,
        time: null,
        count: null
      },
    }
  },
  methods: {
    sendPhoneCode() {
      if (this.sendCodeBtn.isGrey)
        return

      this.$axios.post('/Account/SendPhoneCode', {
        Phone: this.user.phone,
        CodeType: 1001
      }).then((res) => {
            this.$message({
              message: res.data.msg,
              type: res.data.code === 1001 ? 'success' : 'warning'
            })

            if (res.data.code === 1001) {
              this.sendCodeTime()
            }
          }
      )
    },
    sendCodeTime() {
      let TIME_COUNT = 60
      if (!this.sendCodeBtn.time) {
        this.sendCodeBtn.count = TIME_COUNT
        this.sendCodeBtn.isGrey = true

        this.sendCodeBtn.time = setInterval(() => {
          if (this.sendCodeBtn.count > 0 && this.sendCodeBtn.count <= TIME_COUNT) {
            this.sendCodeBtn.count--;
          } else {
            this.sendCodeBtn.isGrey = false;
            clearInterval(this.sendCodeBtn.time);
            this.sendCodeBtn.time = null;
          }
        }, 1000)
      }
    },
    register() {


      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$axios.post('/Account/RegisterUser', this.user).then((res) => {
                this.$message({
                  message: res.data.msg,
                  type: res.data.code === 1001 ? 'success' : 'warning'
                })

                if (res.data.code == 1001) {
                  this.$message({
                    message: "3秒后跳转到登录页面",
                    type: 'success'
                  })

                  setTimeout(() => {
                    this.$router.push({path: '/'})
                  }, 3000)
                }

              }
          )
        } else {

          return false;
        }
      });
    }
  }

}
</script>

<style scoped>

</style>